<template>
  <div>
    <Contact/>
    <section class="slice bgf5">
      <div class="col-lg-12 t-center">
        <h3 class="h3-cp">SPMS科研项目管理系统</h3>
        <p class="lead my-4">
          <a href="javascript:;">满足军工行业管理标准的科研项目管理系统</a>
        </p>
        <p class="lead my-4" style="width: 80vw;margin: 0 auto;text-align: initial;color: #fff;">
          SPMS科研项目管理系统是一种用于组织、协调和监督科研项目的系统，能够对每个项目的整个生命周期进行管理。系统提供了与项目相关活动的准确的各角度视图，帮助科研人员更高效地规划、执行和监控科研项目，并提供了各种功能和工具来简化项目管理过程。
          科研项目管理系统常用功能包括项目入库管理、计划管理、成本管理、风险管理、项目监控、项目仪表盘、项目报表等。
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide v-for="item in dataList" :key="item.alt">
          <img :alt="item.alt" :src="item.img"/>
          <p>{{ item.alt }}</p>
        </swiper-slide>
        <div slot="pagination" class="swiper-pagination"></div>
        <div slot="button-prev" class="swiper-button-prev"></div>
        <div slot="button-next" class="swiper-button-next"></div>
      </swiper>
    </section>


    <section class="slice slice-lg" style="padding-top:30px;">
      <div class="container">
        <div class="col-lg-12 t-center">
          <h3>SPMS产品优势</h3>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">云原生技术</h5>
                <p class=" mt-2 mb-0">
                  微服务架构<br/>
                  容器化部署<br/>
                  自动化管理<br/>
                  面向服务的架构

                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">多种实体文件存储方式</h5>
                <p class=" mt-2 mb-0">
                  MongoDB<br/>
                  FastDFS<br/>
                  minIO
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">可配置的业务模型</h5>
                <p class=" mt-2 mb-0">
                  类型和关系类型<br/>
                  顶层类型可配置<br/>
                  关系类型可配置
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">定制化表单在线编制</h5>
                <p class=" mt-2 mb-0">
                  自定义表单配置<br/>
                  在线管理<br/>
                  实时生效
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">云服务</h5>
                <p class=" mt-2 mb-0">
                  跨域协同<br/>
                  弹性伸缩<br/>
                  高安全性和稳定性
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card text-center hover-translate-y-n10 hover-shadow-lg">
              <div class="px-5 pb-5 pt-5">
                <h5 class="">聚焦式管理</h5>
                <p class=" mt-2 mb-0">
                  工作中心、任务、消息集中管理<br/>
                  便捷式操作<br/>
                  无需频繁切换页面
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>
<script>
export default {
  name: 'SPMS',
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 70,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      dataList: [
        {img: require('../img/index/3/pro1.png'), alt: '计划管理'},
        {img: require('../img/index/3/pro2.png'), alt: '成本管理'},
        {img: require('../img/index/3/pro3.png'), alt: '风险管理'},
        {img: require('../img/index/3/pro4.png'), alt: '项目仪表盘'},
        {img: require('../img/index/3/pro5.png'), alt: '报表管理'},
        {img: require('../img/index/3/pro6.png'), alt: '项目模板管理'}
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/my.scss";

.bgf5 {
  /* 	background:#f5f5f5; */

  background: url('../img/login/4.jpg') no-repeat;
  background-size: 100% 100%;
}


</style>
